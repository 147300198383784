import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Help from "@material-ui/icons/Help";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import faqStyle from "assets/jss/material-kit-react/views/componentsSections/tabsStyle.jsx";

import Button from "components/CustomButtons/Button.jsx";
import { Link } from "gatsby";

class FaqSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <div className={classes.container}>
          <div id="nav-tabs">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <CustomTabs
                  headerColor="info"
                  tabs={[
                    {
                      tabName: "FAQs",
                      tabIcon: Help,
                      tabContent: (
                        <p className={classes.textCenter}>
                          <h3>Frequently Asked Questions:</h3>
                          <h4>What is staking?</h4>
                          <h4>How do I start?</h4>
                          <h4>What are the risks/rewards?</h4>
                          <Link to={"/faq"} className={classes.link}>
                            <Button color="info" size="lg">
                                Learn More 
                            </Button>
                          </Link>
                        </p>
                      )
                    }
                  ]}
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(faqStyle)(FaqSection);
