import React, { useState } from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Settings from "@material-ui/icons/Settings";
import CopyIcon from "@material-ui/icons/FliptoFront";

// core components
import Button from "components/CustomButtons/Button.jsx";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import thisStyle from "assets/jss/material-kit-react/views/componentsSections/tabsStyle.jsx";


function ClipboardCopy({ copyText }) {
  const [isCopied, setIsCopied] = useState(false);

  // This is the function we wrote earlier
  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div>
      {/* <input type="text" value={copyText} readOnly /> */}
      {/* Bind our handler function to the onClick button property */}
      <Button onClick={handleCopyClick} color="danger">
        <CopyIcon/><span>{isCopied ? 'Copied!' : 'Copy'}</span>
      </Button>
    </div>
  );
}


class DelegationDetailsSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <div className={classes.container}>
          <div id="nav-tabs">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <h3>
                  <small>Staking Delegation Details</small>
                </h3>
                <CustomTabs
                  headerColor="danger"
                  tabs={[
                    {
                      tabName: "Details",
                      tabIcon: Settings,
                      tabContent: (
                        <p className={classes.textCenter}>
                          <h3>Ticker: <b>THK</b> <ClipboardCopy copyText="THK" /> </h3>
                          <br/>
                          <h3>THK Stakepool is running on the following fee structure:</h3>
                          <br/>
                          <h3>1% margin + minimum fixed fee (340/epoch)</h3>
                          <br/>
                          <h3>We pledged a total of 50k ADA from our stake</h3>
                        </p>
                      )
                    }
                  ]}
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(thisStyle)(DelegationDetailsSection);
