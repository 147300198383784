import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import InsertLink from "@material-ui/icons/InsertLink";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import howToStyle from "assets/jss/material-kit-react/views/componentsSections/tabsStyle.jsx";

import Button from "components/CustomButtons/Button.jsx";
import { Link } from "gatsby";

class HowToSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <div className={classes.container}>
          <div id="nav-tabs">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <CustomTabs
                  headerColor="success"
                  tabs={[
                    {
                      tabName: "How To Stake",
                      tabIcon: InsertLink,
                      tabContent: (
                        <p className={classes.textCenter}>
                          <h3>How to Stake:</h3>
                          <h4>1. Setup a Wallet</h4>
                          <h4>2. Fund the Wallet</h4>
                          <h4>3. Select a Stake Pool </h4>
                          <h4>4. Share in the Rewards </h4>
                          <Link to={"/howtostake"} className={classes.link}>
                            <Button color="success" size="lg">
                                Learn More 
                            </Button>
                        </Link>
                        </p>
                      )
                    }
                  ]}
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(howToStyle)(HowToSection);
